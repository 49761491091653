import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M11005 5561 c-101 -18 -171 -85 -182 -178 -14 -124 71 -218 207 -230
97 -9 194 36 230 107 24 46 27 134 6 183 -34 84 -152 136 -261 118z"/>
<path d="M7970 5517 c-30 -4 -156 -11 -280 -15 l-225 -7 -3 -36 c-3 -34 -1
-36 37 -48 55 -16 98 -55 111 -99 6 -24 10 -290 10 -734 0 -619 -2 -702 -16
-738 -18 -45 -53 -74 -104 -87 -31 -8 -35 -13 -38 -46 -4 -43 -16 -41 148 -22
158 19 258 19 435 0 77 -8 141 -15 143 -15 2 0 2 17 0 37 -3 33 -7 38 -38 46
-51 13 -86 42 -104 87 -14 34 -16 88 -16 353 1 391 16 485 97 608 75 114 240
122 300 14 49 -87 56 -152 63 -585 6 -310 11 -417 22 -455 54 -184 158 -281
363 -337 115 -31 394 -31 540 0 166 36 306 119 346 206 70 155 -62 292 -307
321 -53 6 -173 -7 -196 -21 -5 -3 6 -17 22 -31 55 -46 75 -97 75 -193 0 -92
-13 -129 -63 -183 -49 -52 -184 -73 -260 -42 -47 20 -78 50 -105 100 -21 39
-22 52 -27 555 -6 488 -7 518 -27 577 -43 130 -126 224 -230 263 -115 43 -309
42 -405 -3 -65 -30 -136 -91 -177 -154 l-31 -46 -2 369 -3 369 -55 -8z"/>
<path d="M6410 5494 c-82 -11 -211 -49 -288 -85 -249 -115 -405 -310 -474
-587 -28 -113 -31 -350 -5 -456 56 -225 173 -393 360 -516 162 -107 326 -153
552 -154 177 -1 269 19 400 87 94 48 210 159 264 250 35 60 75 161 90 228 l9
39 -38 0 c-38 0 -39 0 -55 -57 -51 -178 -181 -336 -335 -411 -190 -93 -405
-96 -553 -8 -103 60 -199 212 -243 382 -47 186 -54 455 -19 681 40 254 143
427 302 505 63 31 75 33 168 33 96 0 103 -1 164 -36 112 -61 165 -168 165
-329 0 -116 -22 -178 -88 -246 l-52 -54 36 -12 c19 -7 81 -13 140 -13 87 0
113 4 153 22 80 37 123 78 159 150 28 57 32 77 32 140 -1 63 -6 83 -38 147
-91 185 -317 293 -631 301 -77 2 -156 1 -175 -1z"/>
<path d="M11190 5039 c-25 -5 -147 -11 -272 -15 l-228 -7 0 -37 c0 -35 2 -38
35 -44 43 -8 94 -50 111 -93 11 -26 14 -128 14 -500 0 -446 -1 -468 -20 -508
-21 -43 -79 -85 -119 -85 -18 0 -21 -6 -21 -40 0 -22 1 -40 3 -40 1 0 58 7
127 15 69 8 175 15 235 15 61 0 166 -7 235 -15 69 -8 126 -15 128 -15 1 0 2
18 2 40 0 34 -3 40 -21 40 -40 0 -98 42 -119 85 -19 40 -20 62 -20 628 0 464
-3 587 -12 586 -7 -1 -33 -5 -58 -10z"/>
<path d="M9749 5020 c-267 -43 -455 -213 -520 -471 -18 -69 -20 -103 -17 -218
4 -116 9 -148 33 -219 25 -75 30 -83 49 -77 85 26 227 22 317 -9 24 -8 44 -14
45 -13 1 1 -5 36 -12 77 -8 45 -14 151 -14 265 -2 298 32 454 116 539 75 75
186 76 262 3 44 -41 68 -91 94 -185 26 -97 37 -457 19 -595 -29 -220 -99 -327
-223 -343 -44 -6 -46 -8 -50 -41 -2 -19 0 -37 4 -39 5 -3 57 -1 116 6 182 19
292 68 402 179 121 121 180 280 180 481 0 208 -58 361 -183 484 -121 120 -254
173 -452 181 -60 2 -135 0 -166 -5z"/>
<path d="M12115 5010 c-197 -31 -364 -129 -450 -265 -104 -161 -131 -418 -69
-634 62 -211 181 -337 379 -398 48 -14 92 -18 215 -17 267 1 457 47 777 191
l101 46 65 -65 c121 -121 255 -172 457 -172 144 -1 234 21 330 79 56 34 140
115 178 172 27 39 27 40 -4 56 -25 13 -29 12 -71 -30 -66 -66 -119 -86 -238
-91 -84 -3 -109 0 -156 17 -67 26 -142 96 -181 173 l-27 52 82 44 c185 100
352 174 522 232 l110 38 -3 48 c-17 261 -212 482 -464 524 -311 53 -576 -101
-674 -391 -54 -159 -54 -366 1 -520 14 -41 28 -81 31 -90 6 -18 -98 -69 -271
-134 -170 -63 -236 -77 -375 -79 -120 -1 -127 0 -177 28 -62 34 -81 53 -126
129 -110 182 -128 617 -35 827 48 107 120 163 213 164 105 2 166 -87 167 -242
1 -97 -20 -163 -69 -214 l-26 -27 29 -8 c16 -4 63 -7 104 -7 166 2 277 100
288 256 10 145 -80 242 -274 293 -72 19 -277 27 -359 15z m1498 -91 c67 -52
127 -266 127 -446 l-1 -98 -167 -91 c-110 -60 -169 -87 -174 -79 -32 52 -34
408 -4 533 20 79 58 154 92 181 35 27 93 28 127 0z"/>
<path d="M7935 3390 c-22 -4 -30 -8 -19 -9 30 -2 34 -23 34 -193 l0 -147 -27
-4 c-16 -2 6 -4 47 -4 41 0 63 2 49 4 -32 5 -39 24 -39 109 0 56 2 62 25 72
40 18 83 15 95 -7 5 -11 10 -53 10 -95 0 -69 -2 -75 -22 -79 -13 -2 6 -4 42
-5 41 0 56 3 40 7 -24 7 -25 10 -25 95 0 80 -2 90 -24 107 -30 24 -58 24 -99
-1 -18 -11 -35 -20 -38 -20 -4 0 -3 41 1 90 4 50 3 89 -1 89 -5 -1 -27 -5 -49
-9z"/>
<path d="M7745 3279 c-16 -12 -32 -24 -35 -27 -3 -2 3 -8 13 -14 15 -8 18 -22
17 -76 -2 -108 -2 -112 25 -124 18 -8 35 -8 70 2 56 16 58 27 3 22 -56 -6 -68
12 -68 100 l0 68 55 0 c30 0 55 5 55 10 0 6 -22 10 -49 10 -46 0 -50 2 -53 26
l-3 26 -30 -23z"/>
<path d="M10205 3279 c-16 -12 -32 -24 -35 -27 -3 -2 3 -8 13 -14 15 -8 18
-22 17 -76 -2 -108 -2 -112 25 -124 18 -8 35 -8 70 2 56 16 58 27 3 22 -56 -6
-68 12 -68 100 l0 68 55 0 c30 0 55 5 55 10 0 6 -22 10 -49 10 -46 0 -50 2
-53 26 l-3 26 -30 -23z"/>
<path d="M12255 3279 c-16 -12 -32 -24 -35 -27 -3 -2 3 -8 13 -14 15 -8 18
-22 17 -76 -2 -108 -2 -112 25 -124 18 -8 35 -8 70 2 56 16 58 27 3 22 -56 -6
-68 12 -68 100 l0 68 55 0 c30 0 55 5 55 10 0 6 -22 10 -49 10 -46 0 -50 2
-53 26 l-3 26 -30 -23z"/>
<path d="M6523 3250 c-12 -5 -27 -19 -33 -31 -17 -31 14 -69 75 -89 86 -29 85
-80 -2 -80 -35 0 -49 6 -68 26 -18 20 -25 23 -25 11 0 -58 138 -77 175 -24 32
46 12 74 -73 102 -29 9 -56 23 -59 31 -9 24 17 44 57 44 30 0 43 -6 59 -27 19
-26 20 -26 21 -7 0 12 -7 27 -15 34 -19 16 -85 22 -112 10z"/>
<path d="M6725 3249 c-22 -3 -30 -7 -17 -8 20 -1 22 -6 22 -55 0 -29 3 -74 6
-99 6 -44 6 -46 -22 -50 -16 -2 3 -4 41 -5 43 0 60 3 45 7 -24 6 -25 11 -28
86 -3 89 6 105 63 105 47 0 55 -15 55 -106 0 -78 -1 -83 -22 -87 -13 -2 6 -4
42 -4 36 0 55 2 44 4 -26 5 -34 29 -34 105 0 48 4 59 22 72 27 19 69 21 86 4
7 -7 12 -44 12 -94 0 -78 -1 -83 -22 -87 -13 -2 9 -4 47 -4 39 0 58 2 43 4
l-28 4 0 85 c0 77 -2 87 -25 109 -29 30 -59 32 -101 6 -31 -19 -32 -19 -48 0
-20 24 -52 24 -98 -1 -34 -18 -37 -18 -40 -2 -2 14 -10 16 -43 11z"/>
<path d="M7200 3243 c-60 -31 -77 -99 -40 -161 22 -36 55 -52 103 -52 l32 1
-40 14 c-22 8 -41 15 -43 15 -9 0 -32 75 -32 105 0 57 14 75 61 75 22 0 51 -7
64 -16 50 -32 57 -137 12 -169 -16 -11 -17 -15 -6 -15 25 0 66 47 71 82 7 42
-7 91 -32 113 -29 26 -107 30 -150 8z"/>
<path d="M7480 3243 c-60 -31 -77 -99 -40 -161 22 -36 55 -52 103 -52 l32 1
-40 14 c-22 8 -41 15 -43 15 -9 0 -32 75 -32 105 0 57 14 75 61 75 22 0 51 -7
64 -16 50 -32 57 -137 12 -169 -16 -11 -17 -15 -6 -15 25 0 66 47 71 82 7 42
-7 91 -32 113 -29 26 -107 30 -150 8z"/>
<path d="M8355 3250 c-31 -6 -34 -7 -12 -9 l27 -1 0 -164 0 -165 -27 -4 c-16
-2 10 -4 57 -4 52 0 72 2 50 6 l-35 6 -3 67 -3 66 34 -10 c48 -15 105 -2 139
32 23 24 28 37 28 77 -1 95 -76 139 -163 94 -36 -18 -37 -18 -37 0 0 10 -3 18
-7 18 -5 -1 -26 -5 -48 -9z m176 -30 c24 -13 49 -61 49 -95 0 -37 -50 -85 -89
-85 -16 0 -41 4 -55 10 -25 9 -26 13 -26 84 0 61 3 77 18 85 22 13 78 14 103
1z"/>
<path d="M8755 3251 c26 -5 30 -11 33 -45 2 -24 -2 -42 -10 -47 -10 -7 -10 -9
0 -9 7 0 12 -14 12 -35 0 -39 -24 -58 -68 -53 -21 2 -27 8 -27 28 0 19 9 29
35 42 37 19 28 24 -13 9 -37 -14 -57 -39 -57 -71 0 -20 6 -31 20 -35 27 -9 76
3 90 20 10 13 16 12 37 -5 14 -11 32 -20 39 -20 23 0 27 21 5 36 -18 13 -21
25 -21 94 0 89 -9 101 -72 99 l-33 -1 30 -7z"/>
<path d="M9195 3249 c-22 -3 -30 -7 -17 -8 20 -1 22 -6 22 -55 0 -29 3 -74 6
-99 6 -44 6 -46 -22 -50 -16 -2 3 -4 41 -5 43 0 60 3 45 7 -24 6 -25 11 -28
86 -3 89 6 105 63 105 47 0 55 -15 55 -106 0 -78 -1 -83 -22 -87 -13 -2 6 -4
42 -4 36 0 55 2 44 4 -26 5 -34 29 -34 105 0 48 4 59 22 72 27 19 69 21 86 4
7 -7 12 -44 12 -94 0 -78 -1 -83 -22 -87 -13 -2 9 -4 47 -4 39 0 58 2 43 4
l-28 4 0 85 c0 77 -2 87 -25 109 -29 30 -59 32 -101 6 -31 -19 -32 -19 -48 0
-20 24 -52 24 -98 -1 -34 -18 -37 -18 -40 -2 -2 14 -10 16 -43 11z"/>
<path d="M9723 3250 c53 -16 76 -53 40 -64 -10 -3 0 -3 22 0 49 7 51 9 35 29
-20 25 -66 45 -97 44 l-28 -1 28 -8z"/>
<path d="M9885 3252 c-35 -4 -37 -6 -15 -11 24 -7 25 -10 28 -103 l3 -97 -28
-4 c-15 -2 4 -4 42 -4 39 -1 61 2 50 4 -30 8 -35 21 -35 95 0 58 3 69 22 82
27 19 79 21 96 4 7 -7 12 -44 12 -94 0 -78 -1 -83 -22 -87 -13 -2 6 -4 42 -4
36 0 55 2 43 4 -22 4 -23 9 -23 89 0 77 -2 87 -25 109 -27 28 -58 31 -95 10
-32 -18 -40 -18 -40 0 0 8 -3 14 -7 13 -5 -1 -26 -3 -48 -6z"/>
<path d="M10423 3250 c-12 -5 -27 -19 -33 -31 -17 -31 14 -69 75 -89 86 -29
85 -80 -2 -80 -35 0 -49 6 -68 26 -18 20 -25 23 -25 11 0 -58 138 -77 175 -24
32 46 12 74 -73 102 -29 9 -56 23 -59 31 -9 24 17 44 57 44 30 0 43 -6 59 -27
19 -26 20 -26 21 -7 0 12 -7 27 -15 34 -19 16 -85 22 -112 10z"/>
<path d="M11233 3250 c53 -16 76 -53 40 -64 -10 -3 0 -3 22 0 49 7 51 9 35 29
-20 25 -66 45 -97 44 l-28 -1 28 -8z"/>
<path d="M11395 3252 c-35 -4 -37 -6 -15 -11 24 -7 25 -10 28 -103 l3 -97 -28
-4 c-15 -2 8 -4 52 -4 44 0 67 2 50 4 -16 3 -33 10 -37 16 -9 15 -8 113 1 147
8 26 3 61 -8 58 -3 -1 -24 -3 -46 -6z"/>
<path d="M11485 3246 c-20 -15 -20 -15 4 -16 14 0 33 -8 44 -17 18 -17 19 -17
15 12 -4 35 -32 44 -63 21z"/>
<path d="M11640 3243 c-60 -31 -77 -99 -40 -161 22 -36 55 -52 103 -52 l32 1
-40 14 c-22 8 -41 15 -43 15 -9 0 -32 75 -32 105 0 57 14 75 61 75 22 0 51 -7
64 -16 50 -32 57 -137 12 -169 -16 -11 -17 -15 -6 -15 25 0 66 47 71 82 7 42
-7 91 -32 113 -29 26 -107 30 -150 8z"/>
<path d="M12043 3250 c-12 -5 -27 -19 -33 -31 -17 -31 14 -69 75 -89 86 -29
85 -80 -2 -80 -35 0 -49 6 -68 26 -18 20 -25 23 -25 11 0 -58 138 -77 175 -24
32 46 12 74 -73 102 -29 9 -56 23 -59 31 -9 24 17 44 57 44 30 0 43 -6 59 -27
19 -26 20 -26 21 -7 0 12 -7 27 -15 34 -19 16 -85 22 -112 10z"/>
<path d="M12445 3252 c-35 -4 -37 -6 -15 -11 24 -7 25 -10 28 -103 l3 -97 -28
-4 c-15 -2 8 -4 52 -4 44 0 67 2 50 4 -16 3 -33 10 -37 16 -9 15 -8 113 1 147
8 26 3 61 -8 58 -3 -1 -24 -3 -46 -6z"/>
<path d="M12535 3246 c-20 -15 -20 -15 4 -16 14 0 33 -8 44 -17 18 -17 19 -17
15 12 -4 35 -32 44 -63 21z"/>
<path d="M12733 3250 c53 -16 76 -53 40 -64 -10 -3 0 -3 22 0 49 7 51 9 35 29
-20 25 -66 45 -97 44 l-28 -1 28 -8z"/>
<path d="M12923 3250 c-12 -5 -27 -19 -33 -31 -17 -31 14 -69 75 -89 86 -29
85 -80 -2 -80 -35 0 -49 6 -68 26 -18 20 -25 23 -25 11 0 -58 138 -77 175 -24
32 46 12 74 -73 102 -29 9 -56 23 -59 31 -9 24 17 44 57 44 30 0 43 -6 59 -27
19 -26 20 -26 21 -7 0 12 -7 27 -15 34 -19 16 -85 22 -112 10z"/>
<path d="M13153 3250 c-12 -5 -27 -19 -33 -31 -17 -31 14 -69 75 -89 86 -29
85 -80 -2 -80 -35 0 -49 6 -68 26 -18 20 -25 23 -25 11 0 -58 138 -77 175 -24
32 46 12 74 -73 102 -29 9 -56 23 -59 31 -9 24 17 44 57 44 30 0 43 -6 59 -27
19 -26 20 -26 21 -7 0 12 -7 27 -15 34 -19 16 -85 22 -112 10z"/>
<path d="M8682 3234 c-12 -8 -22 -22 -22 -30 0 -29 29 -10 43 29 8 21 7 21
-21 1z"/>
<path d="M8891 3243 c9 -2 39 -53 67 -115 l52 -110 -20 -29 c-12 -15 -37 -42
-58 -60 -20 -17 -29 -29 -19 -25 10 3 27 6 37 6 20 0 71 92 135 242 21 50 44
89 53 91 10 3 -8 5 -38 4 -30 0 -45 -2 -32 -4 27 -5 27 -14 3 -86 -35 -101
-35 -101 -81 -7 l-41 85 28 6 c18 4 4 7 -37 7 -36 0 -58 -2 -49 -5z"/>
<path d="M9650 3225 c-15 -16 -24 -41 -28 -73 -3 -41 0 -52 23 -80 15 -18 40
-34 59 -37 40 -8 109 9 127 32 13 15 12 15 -8 5 -12 -7 -40 -12 -62 -12 -47 0
-96 38 -107 83 -6 26 -4 27 32 29 37 1 38 1 7 5 -36 5 -41 17 -17 51 21 30 3
28 -26 -3z"/>
<path d="M10858 3233 c-4 -16 4 -18 96 -15 100 3 100 3 82 -17 -10 -11 -58
-50 -107 -87 -49 -37 -89 -71 -89 -76 0 -4 56 -8 124 -8 104 0 125 3 130 16 3
9 6 16 6 17 0 1 -45 1 -100 -1 -55 -2 -100 0 -100 5 0 4 30 31 68 60 37 28 83
68 101 87 l35 36 -121 0 c-106 0 -121 -2 -125 -17z"/>
<path d="M11160 3225 c-15 -16 -24 -41 -28 -73 -3 -41 0 -52 23 -80 15 -18 40
-34 59 -37 40 -8 109 9 127 32 13 15 12 15 -8 5 -12 -7 -40 -12 -62 -12 -47 0
-96 38 -107 83 -6 26 -4 27 32 29 37 1 38 1 7 5 -36 5 -41 17 -17 51 21 30 3
28 -26 -3z"/>
<path d="M12660 3225 c-15 -16 -24 -41 -28 -73 -3 -41 0 -52 23 -80 15 -18 40
-34 59 -37 40 -8 109 9 127 32 13 15 12 15 -8 5 -12 -7 -40 -12 -62 -12 -47 0
-96 38 -107 83 -6 26 -4 27 32 29 37 1 38 1 7 5 -36 5 -41 17 -17 51 21 30 3
28 -26 -3z"/>
<path d="M10620 3050 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
